import * as jwt from 'jsonwebtoken'

const METABASE_URL = process.env.VUE_APP_METABASE_URL

class MetabaseApi {
  secretId = 'MetabaseEmbeddingSecretKey'

  async getSignedToken (payload = {}) {
    const embed_key = process.env.VUE_APP_METABASE_EMBEDDING_SECRET_KEY // hard coded environment variable
    const token = jwt.sign(payload, embed_key)
    return token
  }

  async getSignedEmbedUrl (
    resource,
    id,
    params = null,
    border = false,
    title = false
  ) {
    const payload = {
      resource: { [`${resource}`]: id },
      params: params || {},
      exp: Math.round(Date.now() / 1000) + (10 * 60)
    }
    const token = await this.getSignedToken(payload)
    const url = `${METABASE_URL}/embed/${resource}/${token}#bordered=${border}&titled=${title}`
    return url
  }
}

export default new MetabaseApi()
