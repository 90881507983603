export const metabaseMixin = {
  data () {
    return {
      innerHeight: null,
      offsetHeight: 190
    }
  },
  created () {
    this.innerHeight = window.innerHeight
    window.addEventListener('resize', this.onResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    resources () {
      return this.$store.getters.resources
    },
    fitHeight () {
      return this.innerHeight - this.offsetHeight
    }
  },
  methods: {
    onResize () {
      this.innerHeight = window.innerHeight
    }
  }
}