<!-- Unused component -->
<template>
  <iframe
    ref="iframeEl"
    :src="path"
    frameborder="0"
    :height="fitHeight"
    :style="iframeStyle"
    allowtransparency>
  </iframe>
</template>
<script>
export default {
  name: 'MetabaseEmbed',
  props: ['path', 'fitHeight'],
  computed: {
    iframeStyle () {
      return ({
        border: 'none',
        width: '100%',
        height: this.fitHeight
      })
    }
  }
}
</script>
